export default {
    URL: process.env.REACT_APP_BACKEND_HOST,
    URL_SUBSCRIPTION: process.env.REACT_APP_SUBSCRIPTION_BACKEND_HOST,
    URL_GET_SIRET: 'https://api.insee.fr/api-sirene/3.11/siret',
    URL_PAY: process.env.REACT_APP_PAYMENT,
    URL_ENERGYPAID: process.env.REACT_APP_ENERGYPAID,
    SUBSCRIPTION_LINK: process.env.REACT_APP_SUBSCRIPTION_URL,
    ApiKey: process.env.REACT_APP_API_KEY,
    SouscriptionApiKey: process.env.REACT_APP_API_KEY_SOUSCRIPTION,
    HOME_PAGE_LINK: 'https://wekiwi.fr/',
    ENV: process.env.REACT_APP_ENV,
    ExpiredSessionTimeout: 3600,

    EnergyPaid: {
        password: process.env.REACT_APP_ENERGYPAID_KEY,
        username: process.env.REACT_APP_ENERGYPAID_USER,
    },

    aws: {
        region: 'eu-west-3',
        apiVersion: '2015-03-31',
        accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY,
    },

    EffectiveStartDate: {
        NOW: {
            EL: {
                businessDays: 0,
                calendarDays: 14,
            },
            NG: {
                businessDays: 0,
                calendarDays: 14,
            },
        },
        LATER: {
            EL: {
                businessDays: 0,
                calendarDays: 14,
            },
            NG: {
                businessDays: 0,
                calendarDays: 14,
            },
        },
    },
    DefaultSwitchDueDate: {
        NOW: {
            EL: 0,
            NG: 0,
        },
        LATER: {
            EL: 0,
            NG: 0,
        },
    },

    FunctionNames: {
        getPackages: process.env.REACT_APP_FUNCTION_GET_PACKAGES,
        getOffers: process.env.REACT_APP_FUNCTION_GET_OFFERS,
        signOrder: process.env.REACT_APP_FUNCTION_SIGN_ORDER,
        searchOrder: process.env.REACT_APP_FUNCTION_SEARCH_ORDER,
        casesExtract: process.env.REACT_APP_FUNCTION_CASES_EXTRACT,
    },

    Stripe: {
        publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },

    GroupsCanSelectAllPackage: ['ADMIN', 'COMDATA'],

    ChannelsFixSwitchLater: [
        /^UBER-SWITCH_VENTEDOMICILE$/,
        /^PIOUI$/,
        /^PIOUI_.+/,
        /^KOMPAR-ENERGIE$/,
        /^KOMPAR-ENERGIE_.+/,
    ],

    ChannelsDoorToDoor: ['C2E', 'DEPANN', 'GROUPE-POWER', 'POWER-DISTRIB'],

    ChannelInformationDocument: ['WEB', 'WEB-ADS'],

    HubspotPortalId : process.env.REACT_APP_HUBSPOT_PORTAL_ID,
    HubspotFormId : process.env.REACT_APP_HUBSPOT_FORM_ID,

};
