import React, { useState, useEffect } from 'react';
import { InputEvent, SelectOption, TSchemaProps } from 'Types';
import { TResponse } from 'Models';

import WordingConstant from '../../../utils/wording.json';
import {
    CustomInput,
    CustomButton,
    CustomSelect,
    CustomModal,
    CustomRadio,
    Group,
} from '../../../components';

import {
    isValidName,
    isValidString,
    isValidEmail,
    isEmptySelectOption,
    getSelectOptionValue,
    formatFirstNameString,
} from '../../../utils/helpers';
import { EResponseCode } from '../../../utils/enums';
import _ from 'lodash';

interface IPayloadUser {
    group: string;
    firstName: string;
    lastName: string;
    email: string;
    civility: string;
    userName?: string;
}

import accents from 'remove-accents';

const Wording = WordingConstant.UserAdministrationResult;

const civilStatuses: Array<SelectOption<string>> = [
    {
        id: Wording.CreateUserModal.civilStatuses.mr,
        label: Wording.CreateUserModal.civilStatuses.mr,
        value: Wording.CreateUserModal.civilStatuses.mr,
    },
    {
        id: Wording.CreateUserModal.civilStatuses.mme,
        label: Wording.CreateUserModal.civilStatuses.mme,
        value: Wording.CreateUserModal.civilStatuses.mme,
    },
    {
        id: Wording.CreateUserModal.civilStatuses.ms,
        label: Wording.CreateUserModal.civilStatuses.ms,
        value: Wording.CreateUserModal.civilStatuses.ms,
    },
];

type Props = {
    schemaProps: TSchemaProps;
    isOpen: boolean;
    createUserResponse: TResponse;
    setOpen: (value: boolean) => void;
    groupList: string[];
    createUser: (props: IPayloadUser) => void;
    resetCreateUserResponse: () => void;
    isCustomerAndContract: boolean;
    currentUser?: any;
};

function getFirstNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidName(value, true)) {
        error = Wording.CreateUserModal.errors.lastNameValidity;
    }
    return error;
}

function getLastNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidName(value, true)) {
        error = Wording.CreateUserModal.errors.lastNameValidity;
    }
    return error;
}

function getMailAddressError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidEmail(value)) {
        error = Wording.CreateUserModal.errors.mailAddressValidity;
    }
    return error;
}

function getCivilityError(value: string | SelectOption<any>): string {
    let error = '';
    if (!isEmptySelectOption(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    }
    return error;
}

function getGroupError(value: string | SelectOption<any>): string {
    let error = '';
    if (!isEmptySelectOption(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    }
    return error;
}

function getUserNameError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.emptyField;
    } else if (!isValidName(value, true)) {
        error = Wording.CreateUserModal.errors.userNameValidity;
    }
    return error;
}

function getClientNumberError(value: string): string {
    let error = '';
    if (!isValidString(value)) {
        error = Wording.CreateUserModal.errors.clientNumberValidity;
    }
    return error;
}

const CreateUserModal: React.FC<Props> = ({
    isOpen,
    setOpen,
    createUser,
    createUserResponse,
    resetCreateUserResponse,
    groupList,
    isCustomerAndContract,
    currentUser,
    schemaProps,
}) => {
    const [clientLastName, setClientLastName] = useState<string>('');
    const [clientFirstName, setClientFirstName] = useState<string>('');
    const [clientEmail, setClientEmail] = useState<string>('');
    const [groupId, setGroupId] = useState<string | SelectOption<any>>('');
    const [civility, setCivility] = useState<string | SelectOption<any>>('');
    const [clientLastNameError, setClientLastNameError] = useState<string>('');
    const [clientFirstNameError, setClientFirstNameError] = useState<string>(
        '',
    );
    const [clientEmailError, setClientEmailError] = useState<string>('');
    const [civilityError, setCivilityError] = useState<
        string | SelectOption<string>
    >('');
    const [groupError, setGroupError] = useState<string | SelectOption<string>>(
        '',
    );
    const [username, setUsername] = useState<string>('');
    const [userNameError, setUserNameError] = useState<string>('');

    const [clientNumber, setClientNumber] = useState<string>('');
    const [clientNumberError, setClientNumberError] = useState<string>('');

    const [isCreatingUser, setCreatingUser] = useState<boolean>(
        !isCustomerAndContract ? true : false,
    );

    useEffect(() => {
        if (clientFirstName !== '' && clientLastName !== '') {
            setUsername(
                `${clientFirstName
                    .charAt(0)
                    .toLocaleLowerCase()}${clientLastName
                    .toLocaleLowerCase()
                    .replace(new RegExp('[_-]+'), '')}`,
            );
        }
    }, [clientFirstName, clientLastName]);

    const { code, message } = createUserResponse;

    const createUserCondition =
        getMailAddressError(clientEmail) === '' &&
        getFirstNameError(clientFirstName) === '' &&
        getLastNameError(clientLastName) === '' &&
        getCivilityError(civility) === '' &&
        getGroupError(groupId) === '' &&
        getUserNameError(username) === '';

    const createClientCondition =
        getMailAddressError(clientEmail) === '' &&
        getFirstNameError(clientFirstName) === '' &&
        getLastNameError(clientLastName) === '' &&
        getCivilityError(civility) === '' &&
        getClientNumberError(clientNumber) === '';

    const elementMap = {
        groupId: {
            id: 'groupe',
            options: _.map(groupList, (group: string, id: number) => ({
                id,
                label: group,
                value: group,
            })),
            handleChangeSelect: (situation: SelectOption<any>) => {
                setGroupId(situation);
                // setGroupError(getGroupError(situation));
            },
            value: groupId,
            text: Wording.group,
            error: groupError,
            schemaProps,
        },

        clientLastName: {
            text: 'Nom: ',
            value: clientLastName,
            onInputChange: (e: InputEvent) => {
                setClientLastName(formatFirstNameString(e.currentTarget.value));
                // setClientLastNameError(getLastNameError(clientLastName));
            },
            error: clientLastNameError,
        },

        clientEmail: {
            text: 'Email: ',
            value: isCustomerAndContract
                ? _.get(currentUser, 'original.contact.email', '')
                : clientEmail,
            onInputChange: (e: InputEvent) => {
                setClientEmail(accents.remove(e.currentTarget.value));
                // setClientEmailError(getMailAddressError(e.currentTarget.value));
            },
            error: isCustomerAndContract ? '' : clientEmailError,
            isLocked: isCustomerAndContract ? true : false,
        },

        clientFirstName: {
            text: 'Prénom: ',
            value: clientFirstName,
            onInputChange: (e: InputEvent) => {
                setClientFirstName(
                    formatFirstNameString(e.currentTarget.value),
                );
                // setClientFirstNameError(
                //  getFirstNameError(e.currentTarget.value),
                // );
            },
            error: clientFirstNameError,
        },

        civility: {
            id: 'Civilité: ',
            defaultValue: '',
            options: civilStatuses,
            handleChangeSelect: (situation: SelectOption<any>) => {
                setCivility(situation);
                // setCivilityError(getCivilityError(situation));
            },
            value: civility,
            text: Wording.civility,
            error: civilityError,
        },

        username: {
            id: 'username',
            text: "Nom d'utilisateur :",
            value: isCustomerAndContract
                ? _.get(currentUser, 'original.id', '')
                : username,
            onInputChange: (e: InputEvent) => {
                const inputValue = e.currentTarget.value;

                // Expression régulière : autorise lettres, chiffres, _ et -
                if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
                    setUsername(inputValue);
                    setUserNameError('');
                } else {
                    setUserNameError("les caractères spéciaux ne sont pas autorisés.");
                }
            },
            error: isCustomerAndContract ? '' : userNameError,
            isLocked: isCustomerAndContract ? true : false,
        },

        clientNumber: {
            id: 'clientNumber: ',
            text: 'N° client :',
            value: isCustomerAndContract
                ? _.get(currentUser, 'original.id', '')
                : clientNumber,
            onInputChange: (e: InputEvent) => {
                if (new RegExp('^[0-9a-zA-Z]*$').test(e.currentTarget.value)) {
                    setClientNumber(e.currentTarget.value);
                }
                // setClientNumberError(
                //    getClientNumberError(e.currentTarget.value),
                // );
            },
            error: isCustomerAndContract ? '' : clientNumberError,
            isLocked: isCustomerAndContract ? true : false,
        },

        btn_validate: {
            title: Wording.btnValidate,
            color: 'gray',
            small: true,
            onClick: () => {
                setClientFirstNameError(getFirstNameError(clientFirstName));
                setClientEmailError(getMailAddressError(clientEmail));
                setClientLastNameError(getLastNameError(clientLastName));
                setCivilityError(getCivilityError(civility));
                setGroupError(getGroupError(groupId));
                setUserNameError(getUserNameError(username));
                setClientNumberError(getClientNumberError(clientNumber));
                if (
                    createUserCondition &&
                    !isCustomerAndContract &&
                    isCreatingUser
                ) {
                    createUser({
                        group: getSelectOptionValue(
                            groupId as SelectOption<string>,
                        ),
                        firstName: clientFirstName,
                        lastName: clientLastName,
                        email: clientEmail,
                        civility: getSelectOptionValue(
                            civility as SelectOption<string>,
                        ),
                        userName: username,
                    });
                    setOpen(false);
                } else if (
                    createClientCondition &&
                    !isCustomerAndContract &&
                    !isCreatingUser
                ) {
                    createUser({
                        group: getSelectOptionValue(
                            groupId as SelectOption<string>,
                        ),
                        firstName: clientFirstName,
                        lastName: clientLastName,
                        email: clientEmail,
                        civility: getSelectOptionValue(
                            civility as SelectOption<string>,
                        ),
                        userName: clientNumber,
                    });
                    setOpen(false);
                } else if (createClientCondition && isCustomerAndContract) {
                    createUser({
                        userName: _.get(currentUser, 'original.id', ''),
                        group: '',
                        firstName: clientFirstName,
                        lastName: clientLastName,
                        email: _.get(currentUser, 'original.contact.email', ''),
                        civility: getSelectOptionValue(
                            civility as SelectOption<string>,
                        ),
                    });
                    setOpen(false);
                }
            },
        },

        btn_reset: {
            title: Wording.buttonReset,
            color: 'orange',
            small: true,
            onClick: () => {
                setClientLastName(''),
                    setClientFirstName(''),
                    setClientEmail(''),
                    setGroupId(''),
                    setCivility(''),
                    setOpen(false),
                    setClientLastNameError(''),
                    setClientFirstNameError(''),
                    setClientEmailError(''),
                    setCivilityError('');
            },
        },

        btn_hide_modal: {
            title: Wording.hideModal,
            color: 'gray',
            small: true,
            onClick: () => {
                resetCreateUserResponse();
                setClientLastName(''),
                    setClientFirstName(''),
                    setClientEmail(''),
                    setGroupId(''),
                    setCivility(''),
                    setOpen(false),
                    setClientLastNameError(''),
                    setClientFirstNameError(''),
                    setClientEmailError(''),
                    setCivilityError('');
            },
        },
    };

    const createUsermodalResponse = () => {
        const { code, message } = createUserResponse;
        return (
            <CustomModal
                show={code && message !== Wording.errorMessage ? true : false}
            >
                <span
                    className="btn-close"
                    onClick={() => {
                        resetCreateUserResponse();
                        setClientLastName(''),
                            setClientFirstName(''),
                            setClientEmail(''),
                            setGroupId(''),
                            setCivility(''),
                            setOpen(false),
                            setClientLastNameError(''),
                            setClientFirstNameError(''),
                            setClientEmailError(''),
                            setCivilityError('');
                    }}
                >
                    {'X'}
                </span>
                <div className="modal-response">
                    <p className="code">
                        {code === EResponseCode.SUCCESS
                            ? Wording.CreateGroupModal.succesMessage
                            : Wording.CreateGroupModal.failureMessage}
                    </p>

                    <p className="message">{message}</p>

                    <div className="search-rs-vl-btn">
                        <CustomButton {...elementMap.btn_hide_modal} />
                    </div>
                </div>
            </CustomModal>
        );
    };

    return (
        <div>
            <CustomModal show={isOpen}>
                <span
                    className="btn-close"
                    onClick={() => {
                        setOpen(false);
                        setClientLastName(''),
                            setClientFirstName(''),
                            setClientEmail(''),
                            setGroupId(''),
                            setCivility(''),
                            setClientNumber(''),
                            setOpen(false),
                            setClientLastNameError(''),
                            setClientFirstNameError(''),
                            setClientEmailError(''),
                            setCivilityError('');
                        setClientNumberError('');
                    }}
                >
                    {'X'}
                </span>
                <div className="create-user-modal">
                    <p className="title">{Wording.CreateUserModal.title}</p>

                    {!isCustomerAndContract && (
                        <div className="radio-buttons">
                            <CustomRadio
                                text={'Créer un utilisateur'}
                                value={'ind'}
                                id={'ind'}
                                checked={isCreatingUser}
                                handleCheck={() => {
                                    setCreatingUser(!isCreatingUser);
                                }}
                            />

                            <CustomRadio
                                text={'Créer un client'}
                                value={'ind'}
                                id={'ind'}
                                checked={!isCreatingUser}
                                handleCheck={() => {
                                    setCreatingUser(!isCreatingUser);
                                }}
                            />
                        </div>
                    )}

                    <div className="create-user-modal__container">
                        <div className="create-user-modal__block">
                            <CustomInput {...elementMap.clientFirstName} />
                            <CustomInput {...elementMap.clientEmail} />

                            {isCreatingUser ? (
                                !isCustomerAndContract && (
                                    <Group {...elementMap.groupId} />
                                )
                            ) : (
                                <CustomInput {...elementMap.clientNumber} />
                            )}
                        </div>

                        <div className="create-user-modal__block">
                            <CustomInput {...elementMap.clientLastName} />
                            <CustomSelect {...elementMap.civility} />

                            {!isCustomerAndContract && isCreatingUser && (
                                <CustomInput {...elementMap.username} />
                            )}
                        </div>
                    </div>
                    <p className="message">{Wording.CreateUserModal.message}</p>
                    <div className="search-rs-vl-btn">
                        <CustomButton {...elementMap.btn_reset} />
                        <CustomButton {...elementMap.btn_validate} />
                    </div>
                </div>
            </CustomModal>
            {code &&
                message !== Wording.errorMessage &&
                createUsermodalResponse()}
        </div>
    );
};

export default CreateUserModal;
