import React, { useEffect } from 'react';
import { CustomButton } from '../../../../../components';
import { buildClassName as bcn } from '../../../../../utils/helpers';
import WordingConstant from '../../../../../utils/wording.json';
import {
    EFirstPaymentCBStatuses,
    EOrderStatuses,
    TContract,
    ECivilities,
    ETimeslots,
    EEffectiveStartRanges,
    TAddress,
    EProcessTypes,
    TOffer,
} from '../../../../../utils/network/types';
import { isCyclicalProduct } from '../../../../../utils/network/helpers';
import offer from '../../../../../components/PDFs/ProofOfPayment/components/RightColumn/Offer';

type Situation = {
    coords?: {
        civility: ECivilities | null;
        firstName: string;
        lastName: string;
    };
    effectiveStartRange?: EEffectiveStartRanges;
    electricityEffectiveStartDate?: Date;
    electricityDueDate?: Date;
    gasDueDate?: Date;
    electricityExpress?: boolean;
    gasEffectiveStartDate?: Date;
    gasExpress?: boolean;
    electricityTimeslot?: ETimeslots;
    gasTimeslot?: ETimeslots;
    electricityFirstPaymentCB?: boolean;
    gasFirstPaymentCB?: boolean;
    billingAddress?: TAddress;
    iban?: string;
    processType?: EProcessTypes;
    thirdParty?: {
        civility: ECivilities;
        firstName: string;
        lastName: string;
    };
};

interface IProps {
    orderReference: string | null;
    didSaveOrder: boolean;
    firstPaymentCBStatus: EFirstPaymentCBStatuses | null;
    highlightSignature: boolean;
    orderStatus: EOrderStatuses | null;
    saveOrder: () => void;
    signatureEnabled: boolean;
    triggerPopin: () => void;
    isPackageValid?: boolean;
    PDLRef: React.RefObject<string | null>;
    PCERef: React.RefObject<string | null>;
    contracts: TContract[];
    finalContracts: TContract[];
    situation: Situation;
    offers: TOffer[] | null;
}

const Wording = WordingConstant.AdminSubscriptionContainer.Subscribe;
const baseClassName = 'subscribe-section';
const buttonsClassName = bcn([baseClassName, 'buttons']);
const saveClassName = bcn([buttonsClassName, 'save']);
const signClassName = bcn([buttonsClassName, 'sign']);

const SubscribeSection: React.FC<IProps> = ({
    orderReference,
    didSaveOrder,
    firstPaymentCBStatus,
    highlightSignature,
    orderStatus,
    saveOrder,
    signatureEnabled,
    triggerPopin,
    finalContracts,
    isPackageValid,
    PDLRef,
    PCERef,
    contracts,
    situation,
    offers,
}) => {
    // const hasEffectiveStartDate = contracts.some(contract => {
    //     return contract.effectiveStartDate !== '';
    // });
    const hasFinalizedOrder =
        orderStatus === EOrderStatuses.FINALIZED ||
        orderStatus === EOrderStatuses.FINALIZED_WITHOUT_DOC;
    const isFinalized =
        hasFinalizedOrder &&
        !!firstPaymentCBStatus &&
        firstPaymentCBStatus !== EFirstPaymentCBStatuses.FINALIZED;

    const signTitleString = isFinalized
        ? Wording.pay.title
        : Wording.sign.title;

    const signButtonClassname =
        highlightSignature && isFinalized
            ? bcn([signClassName, 'highlighted'], '--')
            : signClassName;

    const userGroup = localStorage.getItem('userGroup');
    const isAdmin = userGroup === 'ADMIN';

    const {
        electricityEffectiveStartDate = null,
        gasEffectiveStartDate = null,
    } = situation || {};

    const isCyclic = finalContracts.some(contract => {
        return isCyclicalProduct(contract.chosenProduct);
    });

    const hasAddress = finalContracts.every(value => {
        if (value.deliveryPoint && value.deliveryPoint.address) {
            return (
                value.deliveryPoint.address.street !== null &&
                value.deliveryPoint.address.street !== undefined
            );
        }
        return false;
    });

    return (
        <section className={baseClassName}>
            <h2>{Wording.title}</h2>
            <div className={buttonsClassName}>
                <div className={saveClassName}>
                    {!isCyclic &&
                        situation &&
                        !electricityEffectiveStartDate &&
                        !gasEffectiveStartDate && (
                            <span className="warning-text">
                                Veuillez choisir une date de prélèvement avant
                                de pouvoir enregistrer la souscription.
                            </span>
                        )}
                    <CustomButton
                        color={'white'}
                        onClick={saveOrder}
                        title={Wording.save.title}
                        disabled={
                            !offers ||
                            offers.length === 0 ||
                            !isPackageValid ||
                            (PDLRef.current === null &&
                                PCERef.current === null) ||
                            (!isCyclic &&
                                !electricityEffectiveStartDate &&
                                !gasEffectiveStartDate) ||
                            !hasAddress
                            // !hasEffectiveStartDate
                        }
                    />
                    {(!offers ||
                        offers.length === 0 ||
                        !isPackageValid ||
                        (PDLRef.current === null && PCERef.current === null) ||
                        (!isCyclic &&
                            !electricityEffectiveStartDate &&
                            !gasEffectiveStartDate) ||
                        !hasAddress) && (
                        <span className={'save-warning'}>
                            Une information est manquante pour valider la
                            sauvegarde
                        </span>
                    )}
                    {(!offers || offers.length === 0) && (
                        <span className={'save-warning'}>
                            Tu ne peux pas continuer ta souscription, les offres
                            sont manquantes.
                        </span>
                    )}

                    {didSaveOrder && (
                        <span className={'save-confirm'}>
                            {Wording.save.confirm}
                        </span>
                    )}
                    <span>{Wording.save.hint1}</span>
                    <span>{Wording.save.hint2}</span>
                </div>
                <div className={signButtonClassname}>
                    {isAdmin && (
                        <CustomButton
                            color={isFinalized ? 'orange' : 'gray'}
                            heavy={true}
                            onClick={() => triggerPopin()}
                            title={signTitleString}
                            disabled={
                                !offers ||
                                offers.length === 0 ||
                                !isPackageValid ||
                                (PDLRef.current === null &&
                                    PCERef.current === null)
                            }
                        />
                    )}

                    {isAdmin && !hasFinalizedOrder && (
                        <span>{Wording.sign.hint}</span>
                    )}
                </div>
            </div>
            {orderReference !== null ? (
                <div className="orderNumberDisplay">
                    Votre numéro de souscription a bien été créé :{' '}
                    <strong>{orderReference}</strong>
                </div>
            ) : (
                <></>
            )}
        </section>
    );
};

export default SubscribeSection;
